var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock")]),_vm._v(" Add Time")],1)]}}]),model:{value:(_vm.addClockdialog),callback:function ($$v) {_vm.addClockdialog=$$v},expression:"addClockdialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 white--text green lighten-2"},[_vm._v(" Add Time ")]),_c('v-card-text',[_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}},[_c('v-flex',{staticClass:"pr-1",attrs:{"xs6":""}},[_c('v-autocomplete',{attrs:{"items":_vm.workers,"item-text":"fullname","item-value":"uuid","outlined":"","dense":"","label":"Employee"},model:{value:(_vm.bodyaddclock.employeeUuid),callback:function ($$v) {_vm.$set(_vm.bodyaddclock, "employeeUuid", $$v)},expression:"bodyaddclock.employeeUuid"}})],1),_c('v-flex',{staticClass:"pr-1",attrs:{"xs6":""}},[_c('ma-date-picker',{attrs:{"label":"Date","past":""},model:{value:(_vm.bodyaddclock.day),callback:function ($$v) {_vm.$set(_vm.bodyaddclock, "day", $$v)},expression:"bodyaddclock.day"}})],1)],1),_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}},[_c('v-flex',{staticClass:"pr-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Details","outlined":"","dense":""},model:{value:(_vm.bodyaddclock.details),callback:function ($$v) {_vm.$set(_vm.bodyaddclock, "details", $$v)},expression:"bodyaddclock.details"}})],1)],1),_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"menustart",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.bodyaddclock.dateRange.date1),callback:function ($$v) {_vm.$set(_vm.bodyaddclock.dateRange, "date1", $$v)},expression:"bodyaddclock.dateRange.date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startdatemenu),callback:function ($$v) {_vm.startdatemenu=$$v},expression:"startdatemenu"}},[(_vm.startdatemenu)?_c('v-time-picker',{attrs:{"ampm-in-title":"","full-width":""},model:{value:(_vm.bodyaddclock.dateRange.date1),callback:function ($$v) {_vm.$set(_vm.bodyaddclock.dateRange, "date1", $$v)},expression:"bodyaddclock.dateRange.date1"}}):_vm._e()],1)],1),_c('v-flex',{staticClass:"pr-1",attrs:{"xs6":""}},[_c('v-menu',{ref:"menuend",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.bodyaddclock.dateRange.date2),callback:function ($$v) {_vm.$set(_vm.bodyaddclock.dateRange, "date2", $$v)},expression:"bodyaddclock.dateRange.date2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.enddatemenu),callback:function ($$v) {_vm.enddatemenu=$$v},expression:"enddatemenu"}},[(_vm.enddatemenu)?_c('v-time-picker',{attrs:{"ampm-in-title":"","full-width":""},model:{value:(_vm.bodyaddclock.dateRange.date2),callback:function ($$v) {_vm.$set(_vm.bodyaddclock.dateRange, "date2", $$v)},expression:"bodyaddclock.dateRange.date2"}}):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.cancelAddClock}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":!_vm.validAdd,"color":"primary","text":""},on:{"click":_vm.addClock}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }